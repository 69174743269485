/* @group typography */

@mixin font-title1{
	font-family: $f-stack-title1;
	font-weight: $f-weight-light;
	font-style: normal;
}

@mixin font-title2{
	font-family: $f-stack-condensed1;
	font-weight: $f-weight-light;
}

@mixin font-btn1{
	font-family: $f-stack-button1;
	font-weight: $f-weight-bold;
	font-size: $f-size-s1;
	text-transform: uppercase;
	font-style: normal;
}

//get rid of default styling
@mixin lireset{
	//by default there is no padding or margin on these elements
	//margin:0;
	//padding:0;
	background-image:none;
	.svg &{
		background-image:none;
	}
}

/* @end */