/* @group global vars */

$ua-textsize-px: 16;
$default-textsize-px: 16;


$default-transition-property: all;
$default-transition-duration: 0.5s;
//$default-transition-function: ceaser($easeOutExpo);
$default-transition-function: ease;
$default-transition-delay: 0;

$ui-pad1: 0.65em;

$border-radius1: 3px;

$border-width1: 3px;

/* @end */

/* @group breakpoints */

$bp-xxsmall: 		em(379, $ua-textsize-px);
$bp-xsmall: 		em(479, $ua-textsize-px);
$bp-small: 			em(599, $ua-textsize-px);
$bp-medium: 		em(767, $ua-textsize-px);
$bp-large: 			em(979, $ua-textsize-px);
$bp-xlarge: 		em(1199, $ua-textsize-px);
$bp-xxlarge: 		em(1499, $ua-textsize-px);
$bp-xxxlarge: 		em(1799, $ua-textsize-px);

$max-width1-val: 1024;
//$max-width2-val: 720;

$max-width1: em($max-width1-val, $default-textsize-px);
// $max-width2: em($max-width2-val, $default-textsize-px);

/* @end */

/* @group grids and spacing */

$spacing1: 1em;

$spacing: $spacing1;

$gutter1-px: 44;
$gutter2-px: 44 / 2;
$gutter1: percentage($gutter1-px / $max-width1-val);
$gutter2: percentage($gutter2-px / $max-width1-val);

/* @end */

/* @group colours */
// http://sassme.arc90.com/
// Visualize SASS color functions in real-time without compiling.

// http://chir.ag/projects/name-that-color
// Variable color naming convention


//blues
$c-catalina-blue:	#0C4482;
$c-st-tropaz: 		#255C97;
$c-cornflower: 		#8DB4DE;

$c-milano-red: 		#c10707;

//brand var
$c-brand1: $c-catalina-blue;
$c-brand2: $c-st-tropaz;

//dark
$c-dark1: 		$c-catalina-blue;
// $c-dark2: 		desaturate(adjust_hue(lighten($c-dark1, 5), 17), 7);

$c-mid1: 		#777891;	//footer text colour

$c-light1:		#FFF;
$c-light2:		#d1d8e0;
$c-light3: 		#ebeef0;

$c-white: $c-light1;

$c-body-bg1: $c-brand1;
$c-text1: $c-brand1;
$c-link1: $c-catalina-blue;
$c-border1: $c-brand1;
$c-button1: $c-brand1;

// Forms

$c-input-border: $c-light2;
$c-input-border-focus: $c-catalina-blue;

$c-input-placeholder: $c-mid1;
$c-input-text: $c-text1;

//errors

$c-danger: #de4343;
$c-success: #61B832;
$c-warn: #e47742;
$c-info: #4c98e5;

/* @end */

/* @group fonts */

$f-sans1: 'sofia-pro';
$f-serif1: 'Georgia';

$f-stack-sans1: $f-sans1, Helvetica, Arial, sans-serif;
$f-stack-serif1: $f-serif1, Georgia, Times, "Times New Roman", serif;

$f-stack-title1: $f-stack-sans1;
$f-stack-body1: $f-stack-sans1;
$f-stack-button1: $f-stack-sans1;

/* @end */

/* @group text size */

$lh1: em(26);
$lh2: em(20);

$line-height : $lh1;

$f-size-s1-px: 14;
$f-size-s2-px: 16;
$f-size-s3-px: 18;
$f-size-s4-px: 20;
$f-size-s5-px: 22;

$f-size-s1: em($f-size-s1-px);
$f-size-s2: em($f-size-s2-px);
$f-size-s3: em($f-size-s3-px);
$f-size-s4: em($f-size-s4-px);
$f-size-s5: em($f-size-s5-px);

// $f-size-l1-px: 62;
// $f-size-l2-px: 44;
// $f-size-l3-px: 38;
// $f-size-l4-px: 30;

$h1: 48;
$h2: 36;
$h3: 28;
$h4: 18;
$h5: 16;
$h6: 16;


// $f-size-l1: em($f-size-l1-px);
// $f-size-l2: em($f-size-l2-px);
// $f-size-l3: em($f-size-l3-px);
// $f-size-l4: em($f-size-l4-px);

/* @end */

/* @group font weights */

$f-weight-light: 300;
$f-weight-regular: 400;
$f-weight-bold: 700;

$f-weight-default1: $f-weight-regular;

$sozo-content-link-weight: $f-weight-regular;
$sozo-content-link-decoration: none;
$sozo-content-link-hover: none;

/* @end */

/* @group project specific */

$logo-height1-px: 44;
$logo-width1-px: 223;

/* @end */
