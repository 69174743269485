/* @group general */

body{
	-webkit-font-smoothing: antialiased;
}

a{
	color: $c-link1;
	text-decoration: none;
	border-bottom: 1px solid $c-link1;
	font-weight: $f-weight-bold;
	&:hover{
		text-decoration: none;

	}
}

p{
	margin: 0 0 $spacing1 0;
	line-height: $lh1;
	&:last-child{
		margin-bottom: 0;
	}
	a{
		font-weight: $f-weight-bold;
	}
}

ul{
	list-style:none;
	padding:0;
	margin-top: 0;
	li{
		margin:0;
		padding:0;
	}
}

blockquote{
	font-family: $f-stack-sans1;
	font-style: italic;
    border-left: $border-width1 solid $c-light2;
    line-height: 1.45833333em;
    display: inline-block;
    margin: 0 0 1.25em 0;
    padding: 0 1.5em;
}

hr{
	height: $border-width1;
	border: 0;
	margin: {
		top: em(28);
		bottom: em(28);
	}
	background: $c-light2;
}

/* @end */

/* @group headings */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
.heading{
	padding:0;
	font-weight: $f-weight-bold;
	font-family: $f-stack-title1;
	font-size:1em;
	line-height: $lh2;
	margin: 0 0 $spacing/2;
}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a{
	text-decoration:none;
}

h1,
.h1{
	font-size: em($h1);
	@include bp(max-width, $bp-large){
		font-size: em((40/$h1)*$h1);
	}
	@include bp(max-width, $bp-medium){
		font-size: em((32/$h1)*$h1);
	}
	@include bp(max-width, $bp-small){
		font-size: em((32/$h1)*$h1);
	}
}
h2,
.h2{
	font-size: em($h2);
	@include bp(max-width, $bp-large){
		font-size: em((32/$h2)*$h2);
	}
	@include bp(max-width, $bp-medium){
		font-size: em((26/$h2)*$h2);
	}
	@include bp(max-width, $bp-small){
		font-size: em((26/$h2)*$h2);
	}
}
h3,
.h3{
	font-size: em($h3);
	@include bp(max-width, $bp-large){
		font-size: em((24/$h3)*$h3);
	}
	@include bp(max-width, $bp-medium){
		font-size: em((22/$h3)*$h3);
	}
	@include bp(max-width, $bp-small){
		font-size: em((22/$h3)*$h3);
	}
}
h4,
.h4{
	font-size: em($h4);
}
h5,
.h5{
	font-size: em($h5);
}
h6,
.h6{
	font-size: em($h6);
}



/* @end */