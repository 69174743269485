/* @group spacing */

@mixin pad1{
	padding: $spacing1 0;
}
@mixin pad2{
	padding: ($spacing1 / 2) 0;
}
@mixin margin1{
	margin: $spacing1 0;
}

/* @end */