%btn{
	@include transition1;
	display:inline-block;
	position:relative;
	padding: em(8) em(10);
	font-weight: $f-weight-bold;
}


.btn1{
	@extend %btn;
	color: $c-catalina-blue;
	border: $border-width1 solid $c-catalina-blue;
	background: none;
	&:hover{
		background: $c-catalina-blue;
		color: $c-white;
	}
}

.btn2{
	@extend %btn;
	color: $c-white;
	border: $border-width1 solid $c-white;
	background: $c-catalina-blue;
	background: rgba($c-catalina-blue, 0.75);
	&:hover{
		background: $c-white;
		color: $c-catalina-blue;
	}
}

.btn3{
	@extend %btn;
	color: $c-white;
	border: $border-width1 solid $c-catalina-blue;
	background: $c-catalina-blue;
	&:hover{
		background: transparent;
		color: $c-catalina-blue;
	}
}