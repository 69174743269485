/*------------------------------------*\
    $HOME
\*------------------------------------*/

nav.sectors-navigation{
	@include outer-container;
	padding: em(20);
	margin-top: em(-40);
	position: relative;
	z-index: 5;
	background: $c-white;
	box-shadow: 6px 6px 0 0 $c-light2;
	margin-bottom: $spacing1*2;
	.home &{
		margin-bottom: 0;
	}
	@include bp(max-width, $bp-small){
		max-width: 80%;
	}
	@include bp(max-width, $bp-xsmall){
		max-width: none;
	}
	ul{
		@include clearfix;
		margin-bottom: 0;
	}
	li{
		@include span-columns(4);
		@include bp(max-width, $bp-small){
			@include omega(1n);
			@include span-columns(12);
			margin-bottom: flex-gutter()*2;
		}
		a{
			display: block;
			border-bottom: 0;
			position: relative;
			overflow: hidden;
			&:after{
				@include transition(all 0.3s $ease-out-expo);
				content: "";
				display: block;
				position: absolute;
				top: 0;
				right: -40%;
				width: 100%;
				padding-bottom: 225/325*100%;
				background: rgba($c-white, 0.4);
				@include transform(rotate(-70deg));
			}
			&:hover{
				&:after{
					right: -100%;
				}
				span{
					background: $c-catalina-blue;
					color: $c-white;
				}
			}
			span{
				@include transition(all 0.3s $ease-out-expo);
				background: $c-white;
				padding: em(18, $f-size-s4-px);
				position: absolute;
				top: 50%;
				margin-top: em(-33, $f-size-s4-px);
				width: 80%;
				left: 10%;
				text-align: center;
				font-size: $f-size-s4;
				z-index: 2;
				.csstransforms &{
					margin-top: 0;
					@include transform(translateY(-50%));
				}
			}
		}
		figure{
			display: block;
			padding-bottom: 225/325*100%;
			img{
				@include img-responsive;
				width: 100%;
			}
		}
	}
}

.usps{
	@include clearfix;
	margin:{
		top: em(32);
		bottom: em(32);
		left: auto;
		right: auto;
	}
	@include bp(max-width, $bp-small){
		max-width: 80%;
	}
	@include bp(max-width, $bp-xsmall){
		max-width: none;
	}
	.unit{
		@include span-columns(4);
		@include bp(max-width, $bp-small){
			@include omega(1n);
			@include span-columns(12);
			margin-bottom: flex-gutter()*4;
			&:last-of-type{
				margin-bottom: 0;
			}
		}
		figure{
			background: $c-white;
			box-shadow: 6px 6px 0 0 $c-light2;
			padding: em(10);
			@include span-columns(1 of 4);
			img{
				width: 100%;
			}
		}
		p{
			@include span-columns(3 of 4);
		}
	}
}

.distributors{
	@include clearfix;
	padding:{
		top: em(32);
		bottom: em(32);
	}
	background: $c-white;
	.wrapper{
		@include outer-container;
	}
	h3{
		font-size: $f-size-s5;
		border-bottom: $border-width1 solid $c-light2;
		display: inline-block;
		margin-bottom: 1em;
		text-align: center;
		padding-bottom: em(6);
	}
	ul{
		text-align: center;
		margin-bottom: 0;
	}
	li{
		@include span-columns(1.5);
		@include bp(max-width, $bp-medium){
			@include span-columns(3);
			@include omega(4n);
			margin-bottom: flex-gutter();
		}
		@include bp(max-width, $bp-small){
			@include omega-reset(4n);
			@include span-columns(4);
			@include omega(3n);
		}
		@include bp(max-width, $bp-xsmall){
			@include omega-reset(3n);
			@include span-columns(6);
			@include omega(2n);
		}
		img{
			width: 100%;
		}
	}
}