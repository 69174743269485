.sozo-content{
	a{
		// font-weight: $sozo-content-link-weight;
		// text-decoration: $sozo-content-link-decoration;
	}
	a:hover{
		// text-decoration: $sozo-content-link-hover;
	}

	ol,
	ul{
		list-style: inherit;
		line-height: $lh1;
		padding-left: em(30);
		li{
			margin: 0 0 ($spacing1 * 0.3) 0;
		}
	}
	ol{
		// padding-left: 1.5em;
		// padding-right: 0;
	}
	blockquote{
		//overwrite main typography.scss here with custom styles
	}
	// img{
	// 	max-width: 100%;
	// 	height: auto !important;
	// }

    img[style*="float:left"] {
        margin-right: $spacing1;
    }
    img[style*="float:right"] {
        margin-left: $spacing1;
    }

	// h1, h2, h3, h4, h5{
	// 	@include font-title1;
	// 	line-height: $lh1;
	// }
	// h1{
	// 	font-size: em(36);
	// }
	// h2{
	// 	font-size: em(32);
	// }
	// h3{
	// 	font-size: em(28);
	// }
	// h4{
	// 	font-size:em(26);
	// }
	// h5{
	// 	font-size:em(24);
	// }

}