.news-list{
	@include span-columns(8);
	@include bp(max-width, $bp-medium){
		@include span-columns(12);
	}
	.unit{
		border-bottom: $border-width1 solid $c-light2;
		overflow: hidden;
		padding-bottom: $spacing1*2;
		width: 100%;
		> h3 a{
			border: none;
		}
	}
	.mix{
	    display: none;
	}
	@include direction-context(right-to-left) {
		.image-cont{
			@include span-columns(3 of 8);
			figure{
				margin: 0;
				padding: 0;
				float: none;
				@include aspect-ratio(1,1);
				img{
					@include img-responsive;
				}
			}
		}
	}
}