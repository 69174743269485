.header{
	background: $c-catalina-blue;
	background: rgba($c-catalina-blue, 0.78);
	z-index: 6;
	position: relative;
	.wrapper{
		@include bp(max-width, $bp-small){
			height: em(100);
		}
		@include bp(max-width, $bp-small){
			padding-top: em(12,14);
		}
	}
	.logo{
		$logo-height: em(65);
		$logo-width: em(145);
		border: none;
		z-index:1;
		display:block;
		position:absolute;
		max-width: $logo-width;
		top: 50%;
		margin-top: ($logo-height / -2);
		@include bp(max-width, em(650)){
			top: 60%;
			margin-top: (($logo-height - ($logo-height/100)*25) / -2);
			max-width: $logo-width - ($logo-width/100)*25;
		}
		img{
			width: 100%;
			height: auto;
		}
	}

	.primary-nav{
		float:right;
		display:inline-block;
		> ul{
			float:right;
			margin:0;
			padding:0;
			@include bp(max-width, $bp-small){
				float: none;
			}
			> li{
				line-height: 1em;
				float: left;
				position: relative;
				@include bp(max-width, $bp-small){
					float: none;
				}
				& +li{
					&:before{
						content:"/";
						color: $c-cornflower;
						position: relative;
						@include bp(max-width, $bp-small){
							display: none;
						}
					}
				}
				&:hover{
					.dropdown-menu{
						display: block;
						&:before{
							content: "";
							@include position(absolute, -6px null null 50%);
							@include triangle(12px, $c-white, up);
							margin-left: -6px;
							.csstransforms{
								margin-left: 0;
								@include transform(translateX(-50%));
							}
						}
						@include bp(max-width, $bp-medium){
							display: none;
						}
					}
				}
				a{
					@include transition(all 0.3s $ease-out-expo);
					font-weight: $f-weight-bold;
					font-size: $f-size-s1;
					padding: em(20) em(12) em(22);
					color: $c-white;
					text-transform: uppercase;
					display:inline-block;
					border-bottom: 0;
					border-bottom: 4px solid transparent;
					text-align: center;
					@include bp(max-width, $bp-small){
						width: 100%;

					}
					&:hover, &.active{
						border-bottom: 4px solid $c-white;
						@include bp(max-width, $bp-small){
							background: $c-st-tropaz;
							border-bottom-color: transparent;
						}
					}
					&.active{
						@include bp(max-width, $bp-small){
							background: $c-st-tropaz;
							border-bottom-color: transparent;
						}
					}
				}
			}
		}
		.dropdown-menu{
			@include transition(all 0.3s $ease-out-expo);
			position: absolute;
			top: 100%;
			left: 50%;
			margin-left: -100px;
			min-width: em(200);
			display: none;
			z-index: 4;
			background: $c-white;
			text-align: center;
			box-shadow: 6px 6px 0 0 $c-light2;
			padding: {
				top:  em(18);
				bottom:  em(18);
			}
			@include bp(max-width, $bp-small){
				display: none;
			}
			li{
				width: 100%;
				margin: 0;
				&:last-of-type{
					margin-bottom: 0;
				}
				a{
					text-transform: capitalize;
					padding: em(10);
					color: $c-catalina-blue;
					font-size: em(16);
					border-bottom: 4px solid transparent;
					&:hover,
					&.active{
						border-bottom: 4px solid $c-light2;
						color: $c-text1;
					}
				}
			}
		}
		&.active{
			max-height: 100em;
 		}
 		@include bp(max-width, $bp-small){
			@include transition(all 0.3s $ease-out-expo);
			max-height: 0;
			overflow:hidden;
			position:absolute;
 			top: em(100);
 			left: 0;
 			z-index:9999;
 			width:100%;
 			padding: 0;
 			background: $c-catalina-blue;
		}
	}
	.mobile-menu{
		text-align: center;
		display:none;
		height: em(60);
		width: em(65);
		position: absolute;
		right: 0;
		top: 65%;
		margin-top: em(60/-2);
		padding: em(10);
		text-decoration: none;
		border-bottom: none;
		color: $c-white;
		@include bp(max-width, $bp-small){
			display: block;
		}
		.burger-icon {
    		@include burger(30px, 3px, 6px, $c-white);
		}
		&.active .burger-icon {
		    @include burger-to-cross;
		}
		&:after{
			content: "Menu";
			font-family: $f-stack-sans1;
			font-size: em(12);
			position: relative;
			top: em(-8);
		}
	}
	.utility{
		width: 100%;
		overflow: hidden;
		ul{
			float: right;
			margin-bottom: 0;
			margin-top: em(22,14);
			@include bp(max-width, $bp-small){
				margin-top: 0;
				position: absolute;
				top: 0;
				left: 0;
				float: none;
				width: 100%;
				text-align: center;
			}
			li{
				float: left;
				@include bp(max-width, $bp-small){
					float: none;
					display: inline-block;
					padding: 0;
					&.telephone a{
						padding-right: em(6);
					}
					&.email a{
						padding-left: em(6);
					}
				}
				& +li{
					&:before{
						content:"/";
						color: $c-cornflower;
						position: relative;
					}
				}
				&:hover{
					.dropdown-menu{
						display: block;
						@include bp(max-width, $bp-medium){
							display: none;
						}
					}
				}
				a{
					display: inline-block;
					font-size: $f-size-s1;
					padding: 0 em(12);
					color: $c-white;
					border-bottom: none;
					@include bp(max-width, $bp-small){
						padding: 0;
					}
				}
			}
		}
	}
}