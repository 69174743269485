// =============================================
// Mixin - Img Responsive
// =============================================

@mixin img-responsive {
    max-width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
}
