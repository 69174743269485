/* @group common */

.content{
	width:100%;
	background: $c-light3;
}
.wrapper{
	@include clearfix;
	@include outer-container;
	position:relative;
	@include bp(max-width, em($max-width1-val + 20)){
		@include pad(0 default);
		min-width: em(320);
	}
}

.start{
	clear:both;
}
.media{
	position: relative;
}
.js-cwhidereveal{
	cursor:pointer;
}
.js .cw-hidden{
	display:none;
}
canvas{
	overflow:visible;
}

.old-browser-warning{
	padding: 1em;
	background: $c-warn;
	text-align: center;
	color: $c-white;
	width: 100%;
	position: relative;
	z-index: 9;
}

.icon{
	display: inline-block;
	padding: 0.25em;
	width: em(30);
	height: em(30);
	border-bottom: 0;
	&.icon-facebook{
		background: url(/images/icons/facebook-icon.png) center center no-repeat $c-light2;
		.svg &{
			background-image: url(/images/icons/facebook-icon.svg);
		}
	}
	&.icon-twitter{
		background: url(/images/icons/twitter-icon.png) center center no-repeat $c-light2;
		.svg &{
			background-image: url(/images/icons/twitter-icon.svg);
		}
	}
	&.icon-linkedin{
		background: url(/images/icons/linkedin-icon.png) center center no-repeat $c-light2;
		.svg &{
			background-image: url(/images/icons/linkedin-icon.svg);
		}
	}
}

.breadcrumb{
	margin-top: flex-gutter();
	overflow: hidden;
	background: $c-light2;
	padding: flex-gutter() /2;
	float:left;
	clear: left;
	font-size: $f-size-s1;
	line-height: $lh1;
	list-style: none;
	ul,p{
		float:left;
		margin-bottom: 0;
		padding-left: 0;
	}
	ul li{
		float: left;
		margin: 0;
		& +li{
			&:before{
				content:"/";
				color: $c-cornflower;
				position: relative;
			}
		}
		&:last-of-type{
			a{
				font-weight: $f-weight-bold;
			}
		}
		a{
			border: none;
			padding: 0 em(6);
			font-weight: $f-weight-regular;
		}
	}
}

.main-content{
	padding-bottom: em(40);
	&.no-sector-nav{
		padding-top: $spacing1*2;
	}
	.manufacturer-logo,
	.media{
		background: $c-white;
		padding: em(50) em(25);
		float: right;
		margin: 0  0 em(25) em(25);
	}
	.media{
		padding: em(25);
	}
}

.text-module{
	@include span-columns(8);
	@include bp(max-width, $bp-medium){
		@include span-columns(12);
		@include omega();
		margin-bottom: flex-gutter()*2;
	}
	.about &{
		@include span-columns(6);
		@include bp(max-width, $bp-medium){
			@include span-columns(12);
		}
	}
	.contact &{
		margin-right: flex-gutter();
		@include bp(max-width, $bp-medium){
			@include omega();
		}
	}
	.btn-external{
		float: left;
	}
}

.image-module{
	.no-sector-nav &{
		margin-top: em(-40) - 2em;
		@include bp(max-width, $bp-medium){
			margin-top: 0;
		}
	}
	.about &{
		@include span-columns(6);
		@include bp(max-width, $bp-medium){
			@include span-columns(12);
		}
		.sixteen-nine {
	  		@include aspect-ratio(16, 9);
		}
	}
	.img-cont{
		@include span-columns(6);
		@include omega(2n);
		margin-bottom: flex-gutter();
	}
	figure{
		padding-bottom: 245/245*100%;
		position: relative;
		img{
			@include img-responsive;
		}
	}
}

.full-width{
	padding:{
		top: $spacing1*2;
		bottom: em(40);
	}
	.about &{
		background: $c-white;
	}
}

.product-list{
	@include clearfix;
	+ .btn1{
		margin-top: flex-gutter();
	}
	h3{
		font-size: em($f-size-s5-px);
		margin-bottom: em(20,$f-size-s5-px);
	}
	&.alt-layout{
		.industrial-pneumatics &{
			ul{
				max-width: em(215+215+25);
				margin:{
					left: auto;
					right: auto;
				}
				li{
					@include span-columns(6);
					@include bp(max-width, $bp-xxsmall){
						margin: {
							left: auto;
							right: auto;
						}
						float: none;
						width: 100%;
						max-width: em(280);
					}
				}
			}
		}
	}
	ul{
		padding-left: 0;
		@include clearfix;
		li{
			@include span-columns(4);
			@include omega(3n);
			background: $c-white;
			margin-bottom: flex-gutter();
			text-align: center;
			@include bp(max-width, $bp-small){
				@include omega-reset(3n);
				@include span-columns(6);
				@include omega(2n);
			}
			@include bp(max-width, $bp-xxsmall){
				@include omega-reset(2n);
				margin: {
					left: auto;
					right: auto;
				}
				&:nth-child(2n),
				&:nth-child(3n),
				&:last-of-type{
					margin-right: auto !important;
				}
				float: none;
				width: 100%;
				max-width: em(280);
			}
			a{
				display: block;
				border: none;
				padding: em(20);
				position: relative;
				min-height: 18em;
				@include transition1;
				&:hover{
					box-shadow: 6px 6px 0 0 $c-light2;
					.btn1{
						background: $c-catalina-blue;
						color: $c-white;
					}
				}
			}
			.product-title{
				margin-bottom: em(10);
				min-height: em(52);
			}
			.product-price{
				margin-bottom: em(10);
				color: $c-milano-red;
			}
			figure{
				overflow: hidden;
				margin-bottom: em(10);
				position: relative;
				// padding-bottom: 100/155*100%;
				// padding-bottom: 135/175*100%;
				@include aspect-ratio(1, 1);
				img{
					@include img-responsive;
					top: 50%;
					margin-top: em(-87);
					.csstransforms &{
						margin-top: 0;
						@include transform(translateY(-50%));
						@include bp(max-width, $bp-small){
							left: 50%;
							@include transform(translateY(-50%) translateX(-50%));
						}
					}
				}
			}
			.btn1{
				@include position(absolute, null null em(20) 50%);
				margin-left: em(-90);
				background: white;
				.csstransforms &{
					margin-left: 0;
					@include transform(translateX(-50%));
				}
				width: 220/260*100%;
			}
		}
	}
}

/* @end */
