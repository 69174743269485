.js-rslides {
  position: relative;
  list-style: none;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
	li {
		@include backface-visibility(hidden);
		opacity: 0;
		position: absolute;
		display: none;
		width: 100%;
		left: 0;
		top: 0;
		&:first-child{
			opacity: 1;
			position: relative;
			display: block;
			float: left;
		}
	}
}

.hero-feature{
	margin-top: em(-100);
	padding-top: em(100);
	float: left;
	width: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	.home &{
		padding-top: em(0);
	}
	.sectors &{
		background-image: url('/images/bg/hero-feature/internal-sectors.jpg');
		// @include bp(max-width, $bp-xxlarge){background-image: url('/images/bg/hero-feature/xxlarge/high-performance-products-for-the-toughest-environments.jpg');}
		// @include bp(max-width, $bp-large){background-image: url('/images/bg/hero-feature/large/high-performance-products-for-the-toughest-environments.jpg');}
		// @include bp(max-width, $bp-medium){background-image: url('/images/bg/hero-feature/medium/high-performance-products-for-the-toughest-environments.jpg');}
		// @include bp(max-width, $bp-xxsmall){background-image: url('/images/bg/hero-feature/xxsmall/high-performance-products-for-the-toughest-environments.jpg');}
	}
	.sectors.offshore-oil-and-gas &{
		background-image: url('/images/bg/hero-feature/internal-sector-oil-and-gas.jpg');
		// @include bp(max-width, $bp-xxlarge){background-image: url('/images/bg/hero-feature/xxlarge/high-performance-products-for-the-toughest-environments.jpg');}
		// @include bp(max-width, $bp-large){background-image: url('/images/bg/hero-feature/large/high-performance-products-for-the-toughest-environments.jpg');}
		// @include bp(max-width, $bp-medium){background-image: url('/images/bg/hero-feature/medium/high-performance-products-for-the-toughest-environments.jpg');}
		// @include bp(max-width, $bp-xxsmall){background-image: url('/images/bg/hero-feature/xxsmall/high-performance-products-for-the-toughest-environments.jpg');}
	}
	.sectors.industrial-pneumatics &{
		background-image: url('/images/bg/hero-feature/internal-sector-industrial-pneumatics.jpg');
		// @include bp(max-width, $bp-xxlarge){background-image: url('/images/bg/hero-feature/xxlarge/high-performance-products-for-the-toughest-environments.jpg');}
		// @include bp(max-width, $bp-large){background-image: url('/images/bg/hero-feature/large/high-performance-products-for-the-toughest-environments.jpg');}
		// @include bp(max-width, $bp-medium){background-image: url('/images/bg/hero-feature/medium/high-performance-products-for-the-toughest-environments.jpg');}
		// @include bp(max-width, $bp-xxsmall){background-image: url('/images/bg/hero-feature/xxsmall/high-performance-products-for-the-toughest-environments.jpg');}
	}
	.sectors.control-automation &{
		background-image: url('/images/bg/hero-feature/internal-sector-control-automation.jpg');
		// @include bp(max-width, $bp-xxlarge){background-image: url('/images/bg/hero-feature/xxlarge/high-performance-products-for-the-toughest-environments.jpg');}
		// @include bp(max-width, $bp-large){background-image: url('/images/bg/hero-feature/large/high-performance-products-for-the-toughest-environments.jpg');}
		// @include bp(max-width, $bp-medium){background-image: url('/images/bg/hero-feature/medium/high-performance-products-for-the-toughest-environments.jpg');}
		// @include bp(max-width, $bp-xxsmall){background-image: url('/images/bg/hero-feature/xxsmall/high-performance-products-for-the-toughest-environments.jpg');}
	}
	.news &{
		background-image: url('/images/bg/hero-feature/internal-news-versatile.jpg');
		// @include bp(max-width, $bp-xxlarge){background-image: url('/images/bg/hero-feature/xxlarge/high-performance-products-for-the-toughest-environments.jpg');}
		// @include bp(max-width, $bp-large){background-image: url('/images/bg/hero-feature/large/high-performance-products-for-the-toughest-environments.jpg');}
		// @include bp(max-width, $bp-medium){background-image: url('/images/bg/hero-feature/medium/high-performance-products-for-the-toughest-environments.jpg');}
		// @include bp(max-width, $bp-xxsmall){background-image: url('/images/bg/hero-feature/xxsmall/high-performance-products-for-the-toughest-environments.jpg');}
	}
	.about &{
		background-image: url('/images/bg/hero-feature/internal-about-versatile.jpg');
		// @include bp(max-width, $bp-xxlarge){background-image: url('/images/bg/hero-feature/xxlarge/high-performance-products-for-the-toughest-environments.jpg');}
		// @include bp(max-width, $bp-large){background-image: url('/images/bg/hero-feature/large/high-performance-products-for-the-toughest-environments.jpg');}
		// @include bp(max-width, $bp-medium){background-image: url('/images/bg/hero-feature/medium/high-performance-products-for-the-toughest-environments.jpg');}
		// @include bp(max-width, $bp-xxsmall){background-image: url('/images/bg/hero-feature/xxsmall/high-performance-products-for-the-toughest-environments.jpg');}
	}
	.contact &{
		background-image: url('/images/bg/hero-feature/internal-contact-versatile.jpg');
		// @include bp(max-width, $bp-xxlarge){background-image: url('/images/bg/hero-feature/xxlarge/high-performance-products-for-the-toughest-environments.jpg');}
		// @include bp(max-width, $bp-large){background-image: url('/images/bg/hero-feature/large/high-performance-products-for-the-toughest-environments.jpg');}
		// @include bp(max-width, $bp-medium){background-image: url('/images/bg/hero-feature/medium/high-performance-products-for-the-toughest-environments.jpg');}
		// @include bp(max-width, $bp-xxsmall){background-image: url('/images/bg/hero-feature/xxsmall/high-performance-products-for-the-toughest-environments.jpg');}
	}
	.legal &,
	.errors &{
		background-image: url('/images/bg/hero-feature/internal-about-versatile.jpg');
	}
	.wrapper{
		height: em(250);
		@include bp(max-width, $bp-medium){
			height: auto;
			padding:{
				top: em(45);
				bottom: em(45) + 2em;
			}
		}
		@include bp(max-width, $bp-xsmall){
			padding:{
				top: em(25);
				bottom: em(25) + 2em;
			}
		}
	}
	.rslides {
		list-style: none;
		overflow: hidden;
		padding: 0;
		z-index: 1;
		height: em(480);
		@include bp(max-width, $bp-medium){
			height: auto;
		}
		.wrapper{
			height: em(380);
			@include bp(max-width, $bp-medium){
				height: auto;
			}
		}
		li {
			@include position(absolute, 0 0 0 0);
			-webkit-backface-visibility: hidden;
			display: none;
			width: 100%;
			height: 100%;
			text-align: center;
			background-position: center center;
			background-repeat: no-repeat;
			padding-top: em(100);
			@include bp(min-width, $bp-xxsmall){
				background-size: cover;
			}
			.text{
			}
			// figure{
			// 	top: 50%;
			// 	@include transform(translateY(-50%));
			// }
			&.slide1{
				background-image: url('/images/bg/hero-feature/high-performance-products-for-the-toughest-environments.jpg');
				// @include bp(max-width, $bp-xxlarge){background-image: url('/images/bg/hero-feature/xxlarge/high-performance-products-for-the-toughest-environments.jpg');}
				// @include bp(max-width, $bp-large){background-image: url('/images/bg/hero-feature/large/high-performance-products-for-the-toughest-environments.jpg');}
				// @include bp(max-width, $bp-medium){background-image: url('/images/bg/hero-feature/medium/high-performance-products-for-the-toughest-environments.jpg');}
				// @include bp(max-width, $bp-xxsmall){background-image: url('/images/bg/hero-feature/xxsmall/high-performance-products-for-the-toughest-environments.jpg');}
			}
			&.slide2{
				background-image: url('/images/bg/hero-feature/industrial-products-available-on-the-shelf.jpg');
				// @include bp(max-width, $bp-xxlarge){background-image: url('h/imagesbg//ero-feature/xxlarge/industrial-products-available-on-the-shelf.jpg');}
				// @include bp(max-width, $bp-large){background-image: url('/images/bg/hero-feature/large/industrial-products-available-on-the-shelf.jpg');}
				// @include bp(max-width, $bp-medium){background-image: url('/images/bg/hero-feature/medium/industrial-products-available-on-the-shelf.jpg');}
				// @include bp(max-width, $bp-xxsmall){background-image: url('/images/bg/hero-feature/xxsmall/industrial-products-available-on-the-shelf.jpg');}
			}
			&.slide3{
				background-image: url('/images/bg/hero-feature/supplying-control-panels-to-the-industrial-and-oil-and-gas-industries.jpg');
				// @include bp(max-width, $bp-xxlarge){background-image: url('/images/bg/hero-feature/xxlarge/supplying-control-panels-to-the-industrial-and-oil-and-gas-industries.jpg');}
				// @include bp(max-width, $bp-large){background-image: url('/images/bg/hero-feature/large/supplying-control-panels-to-the-industrial-and-oil-and-gas-industries.jpg');}
				// @include bp(max-width, $bp-medium){background-image: url('/images/bg/hero-feature/medium/supplying-control-panels-to-the-industrial-and-oil-and-gas-industries.jpg');}
				// @include bp(max-width, $bp-xxsmall){background-image: url('/images/bg/hero-feature/xxsmall/supplying-control-panels-to-the-industrial-and-oil-and-gas-industries.jpg');}
			}
			&:first-child {
				position: relative;
				display: block;
				float: left;
			}
		}
	}
	h1, h2{
		color: $c-white;
	}
	h1{
		text-align: left;
		font-size:3em;
		@include bp(max-width, $bp-medium){
			font-size:2.6em;
			text-align: center;
		}
		@include bp(max-width, $bp-small){
			font-size: 2.2em;
		}
		@include bp(max-width, $bp-xsmall){
			font-size: 1.8em;
		}
	}
	h2{
		font-size: em($f-size-s5-px);
	}
	.text{
		@include span-columns(7);
		position: absolute;
		top: 50%;
		margin-top: -100px;
		.sectors.index &{
			top: 40%;
		}
		.csstransforms &{
			margin-top: 0;
			@include transform(translateY(-50%));
		}
		@include bp(max-width, $bp-medium){
			.csstransforms &{
				@include transform(translateY(0%));
			}
			position: relative;
			top: 0%;
			@include span-columns(10);
			@include shift(1);
			@include omega();
			text-align: center;
		}
	}
	figure{
		position: absolute;
		top: auto;
		bottom: 0;
		right: 0;
		@include span-columns(5);
		@include bp(max-width, $bp-medium){
			// @include span-columns(12);
			float: none;
			margin: 0 auto;
			display: none;
		}
		img{
			width: 100%;
		}
		.btn2{
			position: absolute;
			font-size: em($f-size-s4-px);
			top: 65%;
			right: ((325-240)/2)/325*100%;
			@include transform(translateY(-50%));
		}
	}
}