/* @group footer */

#footer{
	background: $c-catalina-blue;
	@include background(linear-gradient($c-catalina-blue, $c-st-tropaz) left repeat);
	color: $c-white;
	a{
		color: $c-white;
		font-weight: $f-weight-regular;
		border-bottom: 0;
	}
	.primary-footer{
		padding: em(50) 0 em(35) 0;
		.unit{
			@include span-columns(4);
			@include bp(max-width, $bp-small){
				text-align: center;
				@include omega(1n);
				@include span-columns(12);
				margin-bottom: flex-gutter()*2;
			}
		}
	}
	.secondary-footer{
		background: $c-catalina-blue;
		padding: em(20);
		li{
			font-size: $f-size-s1;
		}
	}
	.legal{
		text-align: center;
		@include bp(max-width, em($max-width1-val)){
			@include pad(0 default);
			min-width: em(320);
		}
		li{
			display: inline-block;
			& +li{
				&:before{
					content:"/";
					color: $c-cornflower;
					position: relative;
				}
			}
		}
		a{
			padding: 0 0.75em;
		}
	}
}

/* @end */