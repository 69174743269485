// input[type=number], input[type=text], input[type=tel], input[type=email], textarea{
// 	@include transition1;
// 	@include border-radius($border-radius1);
// 	@include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 0.36em rgba($clr-dark2, 0.3));
// 	padding: $ui-pad1;
// 	border: $border-width1 solid darken($clr-light2, 20%);
// 	color: lighten($clr-text-default1, 10%);
// 	background-color: lighten($clr-light2, 2.5%);
// 	font-family: $ff-body1;
// 	&:focus{
// 		@include box-shadow(none);
// 		color:$clr-text-default1;
// 		outline:none;
// 		background-color:$c-white;
// 	}
// }
// input[type=submit]{
// 	border:none;
// 	outline:none;
// }
// fieldset{
// 	margin:0;
// 	padding:0;
// 	border:none;
// }
// label{
// 	margin-bottom:0.5em;
// 	display:block;
// }

// .form{
// 	label, input, textarea{
// 		float:left;
// 		margin-bottom:1em;
// 	}
// 	input, textarea{
// 		font-size: $fss4;
// 	}
// 	label{
// 		width: 100 - $input-width;
// 	}
// 	input, textarea{
// 		width:$input-width;
// 	}
// 	.btn1{
// 		width:auto;
// 		margin: 0;
// 		outline:none;
// 		color:$c-white;
// 	}
// }

fieldset{
	margin: 0;
	border: none;
	padding: 0;
}
/* -------------------------------------------- *
 * Global margin bottom
 */

label, input, textarea{
	margin: $spacing1/1.5 0 $spacing1 0;
}

/* -------------------------------------------- *
 * Labels
 */

label.required:after{
	content: ' *';
	color: $c-danger;
}

/* -------------------------------------------- *
 * Textarea
 */

textarea {
	max-width: 100%;
	width: 100%;
	height:10em;
}


/* -------------------------------------------- *
 * Inputs
 */

input{
	@include transition(all 0.3s $ease-out-expo);
	background: $c-white;
	font-size: $f-size-s3;
	padding: $ui-pad1;
	border-radius: $border-radius1;
	border: $border-width1 solid $c-input-border;
	color: $c-text1;
	font-family: $f-stack-body1;
	&:focus {
		border: $border-width1 solid $c-input-border-focus;
	}
	.validation-failed{
		border-color: $c-danger;
	}
	// Color the outline for focused fields in Chrome, or else the default blue drowns out the 1px red border
	.validation-failed:focus {
		outline-color: tint($c-danger, 50%);
	}
}

input[type=email],
input[type=search],
input[type=number],
input[type=password],
input[type=tel],
input[type=text] {
	width: 100%;
}

/* Only show on iPhones (not iPads or any other device) */
@media only screen and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 0) {
	input[type=email],
	input[type=search],
	input[type=number],
	input[type=password],
	input[type=tel],
	input[type=text],
	select {
		font-size: 16px; /* Prevent from zooming on focus */
	}
}

/* -------------------------------------------- *
 * Placeholder
 */

:-moz-placeholder, -ms-input-placeholder, ::-webkit-input-placeholder{
   color: $c-input-placeholder;
}