.sidebar{
	.no-sector-nav &{
		margin-top: em(-40) - 2em;
		@include bp(max-width, $bp-medium){
			margin-top: 0;
		}
	}
	@include span-columns(4);
	@include omega();
	float: right;
	@include bp(max-width, $bp-medium){
		@include span-columns(12);
	}
	.contact &{
		float: right;
		margin-top: em(-40) - 2em;
		@include bp(max-width, $bp-medium){
			float: none;
		}
	}
	.filter-module{
		padding: em(25);
		background: $c-white;
		box-shadow: 6px 6px 0 0 $c-light2;
		margin-bottom: $spacing1*2;
		h6{
			font-size: em($f-size-s5-px);
		}
		li{
			margin-bottom: em(10);
		}
		a{
			display: block;
			border-bottom: none;
			cursor: pointer;
			.check{
				width: em(30);
				height: em(30);
				float: left;
				background: center center no-repeat $c-light2;
				margin-right: em(6);
			}
			&.active .check{
				background-image: url(/images/icons/check-icon.png);
				.svg &{
					background-image: url(/images/icons/check-icon.svg);
				}
			}
		}
	}
	.contact-module{
		padding: em(25);
		background: $c-white;
		box-shadow: 6px 6px 0 0 $c-light2;
		margin-bottom: $spacing1*2;
		.heading{
			font-size: em($f-size-s5-px);
			margin-bottom: em(20, $f-size-s5-px);
		}
		li{
			margin-bottom: em(5);
			a[href^=tel]{
				border-bottom: none;
			}
		}
	}
	.usps .unit{
		width: 100%;
		@include omega();
		margin-bottom: flex-gutter()*2;
	}
	.product-cta{
		padding: $spacing1*2;
		background: $c-catalina-blue;
		color: $c-white;
		h6{
			font-size: em($f-size-s5-px);
		}
		img{
			float: right;
			margin: $spacing1*-3 $spacing1*-3 0 0;
			padding: 0 0 $spacing1 $spacing1;
		}
	}
	.social-links{
		.icon{
			margin-left: em(10);
			position: relative;
			top: em(6);
		}
	}
}
