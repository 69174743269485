/* @group generic elements */

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body{
	line-height: $lh1;
	height: 100%;
	min-height: 100%;
	font-size: $default-textsize-px + px;
	font-style: normal;
	font-variant: normal;
	font-weight: $f-weight-default1;
	font-family: $f-stack-body1;
	color: $c-text1;
	background: $c-body-bg1;
}

.wf-loading{
	a, p, ol, ul, nav, h1, h2, h3, h4, h4{
		visibility: hidden;
	}
}
.wf-active, .wf-inactive{
	a, p, ol, ul, nav, h1, h2, h3, h4, h4{
		visibility: visible;
	}
}

.main-content{
	font-size:1em;
}

a{
	color: $c-link1;
}

img{
	vertical-align: baseline;
	margin:0;
	display:block;
}

p{
	line-height: $lh1;
}

table{
	border:1px solid $c-border1;
	td{
		padding:1em;
		border:1px solid $c-border1;
	}
}

figure {
	margin: 0;
}

/* @end */


/* @group form feedback */

.sozo-message {
	border-radius: $border-radius1;
	width: auto;
	height: auto;
	padding: em(15) em(15) em(15) em(45);
	font-size: em(16);
	font-weight: bold;
	color: #fff!important;
	border: #fff 1px solid;
}
.sozo-error {
	background: url('/images/icons/sozo-messages/function-alert.png') no-repeat em(10) em(10) $c-danger !important;
	font-weight: bold !important;
}
.sozo-success {
	background: url('/images/icons/sozo-messages/function-tick.png') no-repeat em(10) em(10) $c-success !important;
}
.sozo-warning {
	background: url('/images/icons/sozo-messages/function-information.png') no-repeat em(10) em(10) $c-warn !important;
}
.sozo-information {
	background: url('/images/icons/sozo-messages/function-help.png') no-repeat em(10) em(10) $c-info !important;
}
.sozo-message a	{
	color: #fff!important;
}
#sozo-protection{
	display:none;
}

/* @end */